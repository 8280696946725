.journeycss {
    color: red;
}

.figureImgjourney img {
    width: 100%;
}

.figureImgjourney {
    width: 40px;
    height: 40px;
    float: left;
    padding: 8px;
    background: #17b29836;
    border-radius: 5px;
}

.textfirgurbox {
    margin-left: 52px;
    padding: 2px 5px 2px 0px;
}

.Downicons {
    margin-left: 5px;
}

.spanceuser {
    margin-left: 3px;
}

.manager-4 {
    width: 4%;
}


.tabletext {
    margin: 0px;
    padding: 0px 10px 0px 0px !important;

}

.setW-800 {
    width: 800px;
}


img.colorLogo.seticonwidth {
    width: 25px;
    height: 20px;
}

.journey {
    width: 450px;
}

.journeyInput {
    margin-bottom: 0px;
}


.journeyInput label {
    display: block;
    margin: 0px 0px 5px 0px;
    color: #5D6E84;
    font-size: 14px;
    font-weight: 600;
}

.backsetbtn {
    cursor: pointer;
    padding: 7px;
}

.journeyInput input {
    padding: 10px;
    border: 1px solid #e7eaf0;
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, .2);
    border-radius: 4px;
    outline: none;
    width: 100%;
}

.journeyInput textarea {
    padding: 10px;
    border: 1px solid #e7eaf0;
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, .2);
    border-radius: 4px;
    outline: none;
    width: 100%;
    height: 100% !important;
}

.nofloat.tp-2.confirm.btn-delete.green {
    border-color: #46bfa5;
    color: #fff;
    text-align: center;
}

.nofloat.tp-2.confirm.btn-delete.green:hover {
    background: #33a28a;
    color: #fff;
}

.formbtn .jurneybtn {
    width: 120px !important;
}

.journeyInput select {
    padding: 10px 5px;
    border: 1px solid #e7eaf0;
    border-radius: 4px;
    outline: none;
    width: 100%;
}

.frombtnjourney button {
    color: #46bfa5;
    border: 1px solid #46bfa5 !important;
    border-radius: 25px;
    width: 100%;
    display: block;
    font-size: 14px;
    padding: 9px 10px;
    background-color: #fff;
}

.frombtnjourney button:hover {
    background-color: #46bfa5;
    color: #fff;
}

.frombtnjourney button:disabled {
    color: #afb0b0;
    border: 1px solid #bebebe !important;
    background: transparent;
}

.journeyBody.flexjourney {
    display: flex;
    align-items: center;
    justify-content: center;
}

.w60Journey {
    width: 100%;
}

.w20Journey {
    width: 20%;
}

.marginall {
    margin: 4px 10px 0px 10px;
}

.margnewset {
    margin-top: 24px;
}

.journeyInput.margstep {
    margin-bottom: 10px;
}

.dateJourney .inputStyle,
.react-datepicker__input-container input {
    padding: 9.5px !important;
    font-size: 13px !important;
    border-radius: 3px !important;
}

.editPannelrow {
    width: 100%;
}

.editpanneflex.topmarginevent {
    margin-top: 0px;
    padding: 5px 0px 0px;
    margin-bottom: 0px;
}

.flexheadingPannel.topm {
    margin: 4px 0 5px 0;
    position: relative;
    text-transform: capitalize
}

.fz16 {
    font-size: 16px;
    font-weight: 700;
}

.flexheadingPannel {
    display: flex;
    float: left;
    width: 100%;
}

.draftbtn {
    display: inline-block !important;
    background: #596A80 !important;
    font-size: 12px !important;
    padding: 3px 11px !important;
    border-radius: 17px !important;
    margin: 0px 9px !important;
    font-weight: 600 !important;
    color: #fff !important;
}

.icon-box {
    display: flex;
    align-items: center;
    width: 26px;
    height: 25px;
    border-radius: 5px;
    cursor: pointer;
}

.edit-icon {
    font-size: 13px;
}

.edit-icon,
.project-list-three-dot {
    color: #46bfa5 !important;
    cursor: pointer;
    font-size: 17px;
}

.addStepHeader {
    float: left;
    width: 100%;
}



.sabbtnbox {
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.Savebtn,
.tableHeadCol {
    float: left;
}

.tableHeadCol p {
    padding-top: 0px !important;
}

button.PrimegreenBtn {
    background-color: rgb(255, 255, 255);
    padding: 7px 0px;
    color: rgb(70, 191, 165);
    border-radius: 50px;
    width: 100%;
    min-width: 120px;
    border: 1px solid rgb(70, 191, 165);
    transition: all 0.3s ease 0s;
    font-weight: 600;
}


.relativebox {
    position: relative;
}

.editpanneflex {
    display: flex;
}

button.PrimegreenBtn:hover {
    background-color: rgb(70, 191, 165);
    color: rgb(255, 255, 255);
}


.pannelJourneydesk {
    margin-top: 15px;
}

.serightbox {
    width: 100%;
    text-align: center;
    float: left;
}

.serightboxtop {
    width: calc(100% - 360px);
    text-align: center;
    float: left;
}

.borderingjourny-bttoom {
    margin-bottom: 22px;
}

.pannelstep h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
}

span.greenText {
    color: #46bfa5 !important;
}


.participaintCol {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.colorblack {
    color: #000;
}

.textfirgurbox p {
    color: #000 !important;
    margin: 0;
    font-size: 12px;
}

.textfirgurbox h2 {
    color: #000 !important;
    font-size: 14px;
    font-weight: bold;
    margin: 0 auto;
    max-width: 220px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    display: inline-block;
}

li.activeTab.blackactivetabe {
    color: #46bfa5;
    background: transparent !important;
    font-weight: bold;
}

.pannelstep {
    margin: 0 auto;
    width: 500px;
    text-align: center;
    padding: 10px 0px 10px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2);
    background: #fff;
}

.setStepBox {
    float: left;
    width: 100%;
    margin: 4px 0px 4px;
}



.addpluse {
    width: 1.6px;
    height: 20px;
    display: block;
    margin: 0 auto;
    background: #a8a8a8;
    position: relative;
}

.addpluse::after {
    content: "\f107";
    position: absolute;
    font-family: 'FontAwesome', sans-serif;
    color: #a8a8a8;
    font-size: 20px;
    bottom: -13px;
    left: -5.6px;
}

.stepboxPannel {
    float: left;
    width: 100%;
    position: relative;
}

.stepGrid {
    width: 500px;
    margin: 0 auto;
    position: relative;
}

span.blackeditbtn {
    margin-left: 5px;
}

.stepPannelHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #46bfa5;
    padding: 3px 10px;
    border-radius: 5px 5px 0px 0px;
    min-height: 34px;
}

.headerNumber select {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 2px 10px;
    cursor: pointer;
    outline: none;
}

.headerTExt {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    margin-left: 45px;
}


.dateNewpannel {
    border: 1px solid#ddd !important;
    position: relative;
    top: -1px;
}

.react-datepicker__input-container input {
    padding: 10px !important;
    font-size: 13px !important;
}

.surPluse .edit-icon,
.project-list-three-dot {
    color: #ffffff !important;
}

.headerBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 75px;
}


.headerNumber.removeborer {
    min-width: 75px;
}


.surPluse {
    padding: 3px 8px;
    cursor: pointer;
}

.surPluse:hover {
    opacity: 0.7;
}


.fontCeo {
    text-align: center;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, .2);
    min-height: 53px;
    background-color: #fff;
    position: relative;
}

.surPluse em {
    color: #fff;
    font-size: 15px;
}

a#journey span,
#ai span {
    margin-top: 0px;
}

.buttonArea {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendbtnpopup {
    border: 1px solid #46BFA5;
    color: #46BFA5;
    border-color: #46BFA5;
    padding: 8px 15px;
    float: left;
    border-radius: 30px;
    background-color: #fff;
    font-size: 12px;
    cursor: pointer;
    font-weight: 400;
    width: 80px;
    text-align: center;

}

.sendbtnpopup:hover {
    background-color: #46BFA5;
    color: #fff;
}

.sideMn {
    background: #fff;
    z-index: 100;
    position: absolute;
    width: 377px;
    right: 0px;
    bottom: 0;
    top: 0px;
    overflow: auto;
}

.eventShadow {
    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.sidePopHeader {
    background: #46bfa5;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
}

.sidelist {
    padding: 10px;
}

.sidelist>ul {
    margin: 0px;
    padding: 0px;
}

.sidelist>ul>li {
    list-style: none;
    text-decoration: none;
    margin-bottom: 10px;
}


.sidelistBar {
    background: #f5f5f5;
    margin: 5px 0px;
    padding: 10px 5px;
    border-radius: 3px;
    border: 1px solid#ddd;
    cursor: pointer;
}

.rightDots {
    top: -7px;
}



.sidelistBar span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    position: relative;
    top: -5px;
    background: #ececec;
    border-radius: 3px;
    margin-right: 7px;
    border: 1px solid#ddd
}


li.activemune .sidelistBar {
    background-color: #ddf4ee;
    border-color: #52b8a0;
    color: #000 !important;
}

li.activemune .sidelistBar span {
    background-color: #a5e2d3;
    border-color: #52b8a0;
    color: #fff;
}

.sidelistBar span img {
    width: 23px;
    filter: brightness(0.5);
}

.col-600.journeycolset {
    width: 60%;
}
.col-500.journeycolset {
    width: 50%;
}

.mainrelatesptep {
    position: relative;
}

.col-200.journeycolset {
    width: 15%;
}

.col-44p {
    width: 44px;
}

.manager-20.journeycolom {
    width: 20%;
}

.manager-20 {
    width: 20%;
}

.paddingrighttable {
    padding-right: 20px;
}

.redLink {
    color: #F93C65;
}

.manager-25.journeycolom {
    width: 18%;
}

.manager-45.journeycolom {
    width: 45%;
}

.manager-35 {
    width: 35% !important;
}

.manager-25 {
    width: 25%;
}

.manager-30 {
    width: 30%;
}

.manager-20.userTpey {
    width: 25%;
}

img.addsetIcon {
    width: 16px;
    position: relative;
    top: -2px;
}

h2.headingjerney {
    font-size: 16px;
    font-weight: bold;
}

.creatSurveybtn {
    background: #e8f7f4;
    text-align: center;
    padding: 8px 10px;
    border: 1px solid #7fdec4;
    color: #46bfa5;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    width: 100% !important;
    display: block;
}


span.journeyIcon img {
    width: 16px !important;
}


.searJourney {
    width: 100%;
    padding: 10px 10px 10px 30px;
    border: 1px solid #e7eaf0;
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, 0.2);
    border-radius: 4px;
    outline: none;
}


.searpanneljourneys {
    position: relative;
    margin-top: 10px;
}

span.journeyIcon {
    position: absolute;
    top: 10px;
    left: 8px;
}

.journeypanneltext {
    margin-left: 15px;
}

.journeypanneltext h2 {
    font-size: 14px;
    font-weight: bold;
    margin: 0px;
    padding-top: 0;
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.journeypanneltext p {
    font-size: 12px;
    margin: 0;
}

span.leftPanneljourny {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E9F7F4;
    border: 1px solid#B5E5DB;
    border-radius: 4px;
}

.journeylistpannel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid#DEDEDE;
    margin-top: 10px;
    padding: 8px;
    border-radius: 4px;
}


.selectJourny {
    background: #e8f7f4;
    text-align: center;
    padding: 5px 17px;
    border: 1px solid #7fdec4;
    color: #46bfa5;
    font-size: 12px;
    border-radius: 50px;
    cursor: pointer;
}

.selected {
    border: 1px solid #46bfa5;
    color: #fff;
    background: #46bfa5;
}

.journeylist {
    overflow: auto;
    height: calc(100vh - 320px);
}

h3.headingjourneysur {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    padding-top: 8px;
    line-height: 0;
    margin-left: 40px;
}

.selectJourny:hover {
    background-color: #46bfa5;
    color: #fff;
}

button.nofloat.tp-2.confirm.btn-delete.green.jurneybtn:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.stHeading {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin: 2px 0px 0px 0;
}


.delapform label {
    margin: 0;
}

.delapform input {
    line-height: 1.5;
    border: 1px solid #d7d7d7;
    background: #fff;
    padding: 9px 10px;
    border-radius: 4px !important;
    margin-bottom: 11px;
    outline: none;
}

.delapform select {
    line-height: 1.5;
    border: 1px solid #d7d7d7;
    background: #fff;
    padding: 9px 10px;
    border-radius: 4px !important;
    margin-bottom: 11px;
    outline: none;
}

.delayPannel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.delapform {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.overwhitelare {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
}

.iconhedjourney {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
    background: #46BFA552;
    border-radius: 50px;
    border: 1px solid#46bfa5;
    margin-bottom: 15px;
}

.frombtnjourney {
    margin-top: 30px;
}

.redcolorHeader {
    background-color: #E01A4F;
}

.delayColoarHeader {
    width: 337px;

    .stepPannelHeader {
        background-color: #596A80;
    }
}

.iconhedjourney img {
    width: 100%;
}

.jurneytab {
    margin: 5px 0px 0px 0px;
    padding: 0;
    border-bottom: 2px solid #ddd;
    display: flex;
    justify-content: space-between;
}

.jurneytab>li {
    display: inline-block;
    padding: 10px 0px 10px 0;
    cursor: pointer;
    position: relative;
    font-weight: 600;
    font-size: 12px;
}

.jurneytab li.active::before {
    content: "";
    height: 2px;
    background: #46bfa5;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
}

.sidemnrelate {
    height: 100%;
    position: relative;
}

.formbtn.relatabs {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    height: 60px;
}

.jurneytab li.active {
    color: #46bfa5;
}

.tabPannel.journey>ul>li {
    margin-right: 30px;
}

.tabPannel.journey {
    width: calc(100% + 39px);
    padding: 10px 20px 10px 21px !important;
    margin: -10px 0px 0px -22px !important;
    border: none !important;
}


.infortext {
    margin: 0px;
    font-size: 12px;
    color: #46bfa5;
    font-weight: bold;
}

.bottompannejurney {
    float: left;
    width: calc(100% - 20px);
    height: calc(100vh - 191px);
}

.pannelscrolljourney {
    height: calc(100% - 30px);
    overflow: auto;
    width: calc(100% - 336px);
    float: left;
}

.shorJoutney {
    width: 170px;
    margin: 0px 10px 0px 10px;
}

.col-400 {
    width: 35%;
}

.col-600 {
    width: 53%;
}

.col-100 {
    width: 10%;
}
.col-200 {
    width: 20%;
}

.col-50 {
    width: 10%;
}

.col-150 {
    width: 13%;
}

.barpro {
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid#ddd;
    margin: 7px 5px 0px 0px;
    border-radius: 3px;
}

.green {
    background-color: #46BFA5;
    border: 1px solid#46BFA5
}

.greenlight {
    background-color: #46BFA566;
    border: 1px solid#46BFA5
}

.yellow {
    background-color: #FFDEA2;
    border: 1px solid#FFAE19
}

.gray {
    background-color: #D9D9D9;
    border: 1px solid#D9D9D9
}

.viewbtn {
    cursor: pointer;
    margin-left: 20px;
}

.viewbtn:hover {
    opacity: 0.7;
}

.surveyScrollHeight.journey {
    height: 470px;
}

.surveyScrollHeightscroll {
    height: 460px;
    overflow-y: auto;
}

.journeysize {
    width: 30px !important;
    height: 30px !important;
}

.setjournytext {
    font-size: 14px !important;
}

.rightDots.journeytop {
    top: 0px !important;
    left: 2px !important;
}


.text-danger.removebtnjourney {
    cursor: pointer;
    margin-right: 42px;
    font-weight: 600;
    margin-top: -3px;
}

.text-danger.removebtnjourney:hover {
    opacity: 0.7;
}

.progressbar {
    width: 65% !important;
}

.viewimg {
    width: 30px;
    height: 40px !important;
}

.progressarea .progress {
    background-color: #D9D9D9 !important;
    height: 18px !important;
    border-radius: 50px !important;
}

.progressarea .stopped {
    background-color: rgb(249, 10, 10) !important;
    height: 18px !important;
    border-radius: 50px !important;
}

.progressarea {
    display: flex;
    justify-items: center;
    align-items: center;
    position: relative;
    width: 100%;
}

.countnumber {
    position: absolute;
    left: 8px;
    color: #fff;
    font-size: 12px;
    top: 1px;
    font-weight: 600;
    line-height: 17px;
}


.countcllfull {
    width: 100%;
}

.progress-bar {
    border-radius: 50px !important;
}

button.PrimegreenBtn:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.fullpannel {
    width: 100%;
}

.setnewheight {
    min-height: 350px !important;
}

.setnewheightAdmin {
    min-height: calc(100vh - 375px) !important;
    max-height: calc(100vh - 375px) !important;
}

.allsurveyHeading {
    margin: -5px 0px 0px 8px;
}


img.editIconnew {
    width: 12px;
    position: relative;
    top: -2px;
}


.responsiveScroll.setterwidthjourney {
    width: calc(100% + 30px) !important;
}


img.emailicons {
    width: 18px !important;
}

.sidelistBar:hover span {
    border-color: #46BFA5;
    background-color: #30b49687;
}

.sidelistBar:hover {
    border-color: #46BFA5;
    background-color: #DDF3EE;
}


.sidelistBar:hover img {
    fill: #fff !important;
    filter: brightness(10);
}


.journeylist.surveyste {
    overflow: auto;
    height: calc(100vh - 345px);
}

.dateJourney {
    position: relative;
}

.dateJourney .react-datepicker__input-container {
    height: 40px !important;
}

.calandericonset {
    position: absolute;
    top: 9px;
    right: 9px;
    width: 20px;
}

.calandericonset img {
    width: 100%;
}

.dateJourney:hover .calandericonset {
    opacity: 0.7;
}


.projectRecipientsForm {
    // height: 640px !important;
    width: 530px;
}


.icon-box.ml-1.martop.testtopspace {
    margin-top: 1.5px;
}

.headerNumber.removeborer select {
    border: none !important;
}

.pannelscrolljourney.fullpannel .stepGrid {
    padding-left: 3px;
}

.colorbland {
    float: left;
    background: #f3f3f3;
    width: calc(100% + 40px);
    margin-left: -20px;
    position: relative;
}

.userlistjourneytable {
    padding: 0px 15px;
}

.classicbackbtn {
    position: relative;
    margin-top: -36px;
}

.pannelJourneydesk.serightboxtop.fullpannel .pannelstep {
    position: relative;
    left: -11px;
}

.journeyInput.margstep .rdw-editor-wrapper {
    border: 1px solid#ddd;
    border-radius: 4px !important;
}


.journeyInput.margstep .rdw-editor-toolbar {
    padding: 5px 0px 0px 0px !important;
    border-bottom: 1px solid #F1F1F1 !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-radius: 0px !important;
}


.journeyInput.margstep .public-DraftStyleDefault-block {
    margin: 0em 0 !important;
}


.fileSelect {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid#ddd;
    border-radius: 5px;
    flex-direction: column;
}

.fileSelect input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
}

.eventLab {
    color: #5d6e84;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
    position: relative;
}

span.filedrag img {
    width: 30px;
    margin-top: 14px;
}

.topdistanspace {
    margin-top: 10px;
}


.flexinput.extradesign img.frontimg {
    object-fit: fill;
    max-height: 30px;
    margin-top: 2px;
    width: 27px;
    float: right;
    margin-right: 7px;
}

.flexinput.extradesign .closebtnImgcenter {
    top: -8px;
    right: 10px;
    background: #e7eaf0;
    position: absolute;
    width: 20px;
    height: 20px;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    font-size: x-small;
    cursor: pointer;
    color: #fff;
}

.closebtnImgcenter img {
    width: 8px;
}



.surveyboxPoup {
    z-index: 1000;
}

.shadebox {
    z-index: 1000;
}


.boltfont {
    font-weight: 600;
}


.text-sucess:hover {
    opacity: 1;
    border-bottom: none;
}

.searchboxRight {
    width: calc(90% - 233px);
}

.dottername {
    max-width: 712px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

label.textleftfile {
    float: left;
}

.inputfildFile {
    position: relative;
    float: right;
    width: 100%;
    height: 80px;
}

.centeraldata {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    padding: 30px 0px;
    color: #5d6e84;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.nodatajourney {
    height: 500px !important;
}

.flexinput {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

select.fileinput {
    width: 25%;
    height: 38px;
    position: relative;
    margin-top: -2px;
}

.flexinput.extradesign .inputfildFile {
    height: 40px;
}


.flexinput.extradesign .fileSelect {
    align-items: center;
    justify-content: normal;
    flex-direction: row;
    height: 38px;
}



.flexinput.extradesign span.filedrag img {
    margin-top: 2px;
    margin-left: 6px;
}


.flexinput.extradesign .fileSelect p {
    margin: 0px 0px 0px 5px;
}


.actiondetails {
    cursor: pointer;
    color: #5d6e84;
    font-weight: 600;
}

.actiondetails:hover {
    opacity: 0.7;
}

.stepPannelHeader.aligncentertext {
    text-align: center;
    justify-content: center;
}

.journeyStatus {
    background: #f3f3f3;
    float: left;
    width: calc(100% + 40px);
    height: calc(100vh - 49px);
    margin-left: -22px;
}

.whitejourney {
    background: #fff;
    padding: 10px 15px;
}

.padsJourney {
    padding: 0px 15px;
    margin-top: 20px;
}

.promtTextForm {
    margin: 0px;
    color: #5d6e84;
    font-weight: 600;
}

.flexheadingPannel.topmnag {
    position: relative;
    top: -3px;
}

.recipientsSelectText.journeymargin {
    margin: -3px 0px 0px 8px;
    font-size: 12px;
    padding: 0;
}

p.smallTextjouney.nmarginleft.text-capitalize.mtj-0.journeytext {
    margin-left: 0px !important;
}

.nmarginleft {
    margin-left: 0px !important;
}


.smallTextjouney.text-capitalize.mtj-0.journeytext {
    margin: -7px 0px 0px 8px !important;
    font-size: 10px !important;
}

.smallTextjouney {
    margin: -5px 0 0 18px;
    font-size: 12px;
    color: #6c757e;
}



.panneljourney .chk .checkmark:after {
    left: 10px;
    top: 5px;
    width: 5px;
    height: 12px;
}

.panneljourney .checkmark {
    height: 25px;
    width: 25px;
}

.panneljourney {
    display: flex;
    align-items: center;
}

.checkText {
    margin: 0;
    font-size: 14px;
    color: #303029;
    margin-left: 25px;
    line-height: 21px;
}

.filterTExt {
    color: #5D6E84;
}

.restableBorder.borderall.borderpadding.borderingjourny-bttoom.responsive-symba-scroll.paddingsetjourney {
    padding-bottom: 10px;
}

.fullgreenbtn {
    background: #46bfa5;
    color: #fff;
}

.fullgreenbtn:hover {
    background-color: #2d8f7a;
    color: #fff;
}

.chk:hover input~.checkmark.nohover::after {
    background: none;
}

.mtj-0 {
    margin-top: 0px !important;
}

.lefticonjourne {
    width: 40px;
    height: 40px;
    float: left;
}

.lefticonjourne img {
    width: 100%;
}


.righttextjourne {
    margin-left: 45px;
}

.righttextjourne h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    padding-top: 3px;
}

.righttextjourne p {
    font-size: 12px;
    color: #6c757e;
    font-weight: bold;
}

.bodyfield {
    height: calc(100vh - 360px) !important;
}



.editPannelrow.topborderevent.journey {
    margin-bottom: 0px !important;
}

.editPannelrow.topborderevent.journey .editpanneflex.topmarginevent {
    margin-top: 0 !important;
    padding: 3px 0;
    margin-bottom: 0px !important;
}



.poupMain.journeypoupmain {
    max-width: 1500px;
    background: #fff;
    position: fixed;
    top: 11%;
    left: 50%;
    border: 1px solid#ddd;
    transform: translate(-50%, -11%);
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    height: calc(100% - 82px);
    border-radius: 6px;
}

.poupMain .sidePopHeader {
    background: #fff;
    text-align: center;
    padding: 10px;
    color: #303030;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid#ddd;
    margin-bottom: 20px;
}

.sidemnrelate.setpoup {
    width: 1200px;
    text-align: center;
    padding: 0px 14px;
}

.poupMain .buttonArea.mt-3.d-flex.justify-content-center {
    justify-content: revert !important;
    padding-left: 10px;
}


.shwoblack {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
}

.shwoblack+.shatoff {
    background-color: transparent !important;
}

.leftbutton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    left: 0;
    right: 10px;
    top: 7px;
}

.poupMain .journeyInput.margstep {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    height: calc(100% - 104px);
}


.poupMain .nCancilBtn {
    padding: 6px 15px !important;
}

.stalltitile {
    top: -7px !important;
    position: relative;
    width: calc(100% - 50px);
}

.leftbutton.setnextbottom {
    top: 10px !important;
    left: auto;
    right: 24px;
}

.poupMain .journeyInput.margstep.setforattachment {
    width: 220px;
}

.leftbutton.setcrossnew {
    left: auto;
    right: 13px;
    top: 13px;
}

.setoutherhether {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.poupMain .sidelist.emainpanneljurney {
    height: 100%;
}

.poupMain .inputfars {
    height: 100%;
}

.poupMain .setalternet {
    display: inline-block !important;
    margin-left: 22px;
}

.poupMain .flexinput.extradesign .closebtnImgcenter {
    top: -5px !important;
    left: -9px !important;
    right: auto;
}

.poupMain .ql-container {
    height: 90% !important;
}

.poupMain .journeyInput {
    width: 100%;
}

.poupMain .ql-container.ql-snow {
    border: none !important
}


.poupMain .setoutherhether {
    border: none;
}

.poupMain .ql-editor {
    overflow-y: inherit;
}

.showEditorCl {
    display: block;
}

.ql-toolbar.ql-snow {
    position: absolute;
    z-index: 9999999;
    background: #fff;
    top: -41px;
    left: 0px;
    width: 810px;
}

.eventeditBox {
    margin-bottom: 35px;
    box-shadow: none !important;
}

.poupMain .flexinput.extradesign img.frontimg {
    float: left;
}

.showEditor {
    display: none;
}

.showEditorCl {
    display: block;
}

.setDrowp .fileSelect {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    flex-direction: row;
    height: 40px;
    width: 220px;
}

.setDrowp span.filedrag img {
    width: 30px;
    margin-top: 0px;
}

.setDrowp .fileSelect p {
    margin: 0px;
}

.setDrowp .inputfildFile {
    height: 60px;
    display: flex;
    align-items: center;
}

.mainbottompoup {
    padding-left: 22px;
}

.position-relative.releativeboxheight {
    height: 100%;
}

input.eventeditBox.magbetomjourney {
    margin-bottom: 0px;
}

.panneljourney .chk:hover input~.checkmark {
    background-color: #53af99 !important;
}


.panneljourney .chk:hover input~.checkmark.nohover.nothower {
    background-color: #eee !important;
}

.journeyCheckpannel {
    margin-left: 43px;
}

.panneljourney .chk {
    cursor: inherit !important;
}

.setlinkall {
    position: relative;
    float: left;
    width: 100%;
}


.journeyCheckpannel.responsiveScroll {
    height: 450px;
    overflow: auto;
}

h3.datejourney {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 2px;
    position: absolute;
    right: -79px;
    bottom: -27px;
    color: #8d8d8d;
}

.Draftbtn {
    color: #ffa705;
    position: relative;
    font-weight: 600;
}

// .Draftbtn::after {
//     content: "";
//     width: 6px;
//     height: 6px;
//     background: #ffa705;
//     border-radius: 5px;
//     position: absolute;
//     top: 6px;
//     left: -10px;
// }

.Publishedbtn {
    color: #46bfa5;
    position: relative;
    font-weight: 600;
}

// .Publishedbtn::after {
//     content: "";
//     width: 6px;
//     height: 6px;
//     background: #46bfa5;
//     border-radius: 5px;
//     position: absolute;
//     top: 6px;
//     left: -10px;
// }

.viewpannel {
    color: #fff;
    position: relative;
    border-bottom: 1px solid transparent;
    font-weight: bold;
    cursor: pointer;
    padding: 0 5px;
}

.viewpannel img {
    width: 16px;
    position: relative;
    top: -1px;
}

.viewpannel:hover {
    opacity: 0.7;
}

.viewpannel:hover .journeyInfor {
    display: block;
}

input.eventeditBox.nomarginjourney.null {
    margin-bottom: 0px;
}

.sidelist.emainpanneljurney.heightsetforPopup {
    height: calc(100% - 60px);
}

.inputfars.heghtpopuptest {
    height: 100%;
}

.journeyInput.margstep.seteightjourney {
    height: calc(100% - 130px);
}


.shadeJourney {
    background: rgba(255, 255, 255, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.viewStep {
    background: #fff;
    z-index: 10000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    border-radius: 5px;
    padding: 15px;
    padding-top: 0;
    border: 1px solid#ddd;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    width: 100%;
}

.viewSteprelateive {
    position: relative;
}

.fontheadingjourney {
    position: relative;
    background: #46bfa5;
    color: #fff;
    font-size: 15px;
    width: calc(100% + 30px);
    left: -15px;
    padding: 10px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px 5px 0px 0px;
    top: 0;
}

.closepost {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 100;
    opacity: 0.7;
    cursor: pointer;
}

.borderjourneypoup h2 {
    font-size: 19px;
    font-weight: bold;
}

.closepost:hover {
    opacity: 1;
}

.fa-eye {
    font-size: 17px;
}


.leftbtnjourney {
    float: right;
    position: absolute;
    right: 20px;
    top: 7px;
}

.felxtjourney {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 57px;
    padding: 0px 23px;
    padding-top: 23px;
}

.felxtjourney .inputfildFile {
    width: 215px !important;
}

.centeraldata img {
    width: 80px;
}

.centeraldata h2 {
    font-size: 16px;
    font-weight: bold;
    padding: 14px 0px 5px;
}


.setinputagain.null {
    width: 100% !important;
    position: relative;
    left: 0px;
}

.surveyMainBody.journeywidth {
    width: 816px;
}

.surveyBox.journey {
    padding: 30px 10px;
    width: 160px;
}

.surveyButton.journeysetbtn {
    padding: 0px 50px;
}

.surveyButton.journeysetbtn .surveyGreenBtnLg {
    padding: 8px;
}

.cancelbtnjourney.button.delete-button.cancelBtn.nCancilBtn {
    position: absolute;
    right: 105px;
    background-color: #535252 !important;
    color: #fff !important;
}

.cancelbtnjourney.button.delete-button.cancelBtn.nCancilBtn:hover {
    background-color: #2e2e2e;
}

.nofloat.tp-2.confirm.btn-delete.green.jurneybtn.jouneyaddNewbtn {
    width: auto !important;
    position: relative;
    padding: 6px 0px;
    margin-top: -2px;
}

.betatextset {
    position: absolute;
    right: 16px;
    font-size: 10px;
    background: #ffffff;
    padding: 1.5px 4px 0px 4px;
    border-radius: 4px;
    color: #000 !important;
    font-weight: bold;
}

.setoutherhether.relativebox {
    position: relative;
}



nav.customeMenubar li.nav-item:hover .betatextset {
    color: #000 !important;
}

.inputfildFile {
    margin-top: 0px !important;
}

.searchFilter {
    display: block;
    width: 100%;
    border: 1px solid #E7EAF0;
    padding: 6px 10px 6px 25px;
    border-radius: 5px;
}

.surveySearch.marTop {
    margin: 15px 0px 0px;
}

.surveySearch {
    position: relative;
    width: calc(100% - 462px) !important;
}

.linkcolumn {
    display: flex;
    align-items: center;
    transition: 0.3s;
    width: 100%;
}

p.tabletext.paddingtext {
    margin-left: 3px;
}

.journeyTable .participaintHeader {
    padding: 14px 0;
    background: #fff;
    border-bottom: 1px solid #eaeaea;
    flex-flow: row wrap;
}

.mailerscroll {
    width: 100%;
    overflow: auto;
    max-height: 500px;
    height: 100%;

    p {
        margin: 0;
        padding: 0;
    }
}

.mailerscroll.popupdatas a {
    color: #46BFA5;
    font-weight: bold;
}

.headingheaderpoup h2 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    top: -13px;
    padding: 0px 18px;
}


.journeyInput.margstep .rdw-editor-wrapper {
    height: calc(100% + 134px);
    overflow: hidden;
}

.bodyfield.setforallup.null {
    height: calc(100vh - 330px) !important;
}

.poupMain.journeypoupmain.wfulljourney {
    width: 1190px;
}

.rdw-editor-main {
    padding: 10px 15px !important;
}


.newupjourney .formbtn.relatabs {
    height: 42px;
}

.newupjourney .justicficenter {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    margin-right: 5px;
    position: relative;
    z-index: 1;
}

.newupjourney .poupMain .nCancilBtn {
    padding: 8px 15px !important;
}

.bodyfield {
    height: calc(100vh - 215px) !important;
}

.bodyfield.setforallup.errorBorder .wrapper-class.rdw-editor-wrapper {
    border: none !important;
}

.noCournt {
    float: left;
    margin: 8px 0 0 10px;
    padding: 0px;
}


.rdw-image-imagewrapper img {
    width: 100% !important;
}

.setforNewdropdown.newdrowpSurvey {
    position: absolute;
    top: -8px;
    right: -10px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.mailerscroll.popupdatas p {
    line-height: inherit !important;
}

span.betatagforjourney {
    font-size: 12px;
    background: #46bfa5;
    color: #fff;
    position: relative;
    top: 0px;
    right: -1px;
    padding: 1px 6px;
    border-radius: 4px;
    font-weight: 300;
}

h2.journeyHeadingtable {
    margin-top: 2px;
}

p.jouirneyparatable {
    margin: -7px 0px 0px 0px;
}

.fadesetmove {
    position: absolute;
    width: 351px;
    height: 50px;
    background: #fff;
    right: 0px;
    z-index: 1;
    bottom: calc(100vh - 743px);
}

.siterDraftjourney {
    display: inline-block;
    background: #596A80;
    font-size: 12px;
    padding: 2px 10px 1px;
    border-radius: 17px;
    margin: 0px 4px 0px 7px;
    font-weight: 600;
    color: #fff;
    position: relative;
    top: -1px;
}

button.PrimegreenBtn.ml-2.setwidthpal.jouirneypublishbtn {
    margin-top: 3px;
}

.icon-box.ml-1.martop.testtopspace.journeyhead {
    margin-top: 0px;
    position: relative;
}

.newpaddingforintern {
    padding: 10px !important;
}

.winstedot {
    max-width: 37%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
    padding-right: 10px;
}

.journeytopspace {
    top: -50px !important;
}

.canceljournenewbtn {
    max-width: 80px !important;
    padding: 6px 15px !important;
    position: relative;
    top: 1px;
}

.mr-2.button.delete-button.cancelBtn.nCancilBtn.jurneybtn.canceljournenewbtn {
    padding: 6px 15px !important;
}



.jurneytab.launchpadannouce>li {
    font-size: 14px;
    margin-right: 18px;
    padding: 3px 15px;
}

.stopJourneybutton {
    background: #e01a4f;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    border: none;
    border-radius: 50px;
    width: 110px;
    height: 31px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 7px;
    position: relative;
}

.stopJourneybutton:hover {
    background-color: #d83a64;
}

.stopJourney {
    background: #e01a4f;
}

.pointJourneyText {
    cursor: pointer;
    font-weight: 600;
}

.pointJourneyText:hover {
    opacity: 0.8;
}

.journeyStopText {
    cursor: pointer;
    font-weight: 600;

    :hover {
        opacity: 0.5;
    }
}

.relateStopJourney {
    position: relative;
    top: -4px;
}

.redDotted {
    color: red;
    position: relative;
    font-weight: 600;
}



.JoundPannel p {
    padding: 4px 22px;
    font-weight: bold;
    text-align: center;
}


.greentext {
    color: #46bfa5;
}

.JoundPannel {
    margin: 0;
    font-size: 12px;
    font-weight: bold;
    color: #5d6e84;
    position: relative;
    top: 9px;
}

.JoundBox h2 {
    font-size: 14px;
    background: #46bfa5;
    padding: 9px 30px;
    border-radius: 5px 5px 0px 0px;
    color: #fff;
    font-weight: bold;
    text-align: center;
}

.deviderStatus {
    position: relative;
    top: -1px;
    color: #565656;
}

.emailStatus {
    color: #5d6e84;
    font-size: 13px;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}

.scrollEvent .stepPannelHeader {
    animation: blink 1s;
    animation-iteration-count: 6;
}

.user_blur {
    opacity: 0.5;
}

.gcaladded {
    margin: 0;
    padding: 0;
    width: 200px;
    border: 1px solid #E7EAF0;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    opacity: 0.7;
}

.saveDraftbtn {
    min-width: 90px !important;
}




@keyframes blink {
    50% {
        background: red;
    }
}




.delet_btn_journey {
    border: 1px solid #dc3545;
    color: #dc3545;
    padding: 6px 20px;
    border-radius: 30px;
    font-size: 12px;
    cursor: pointer;
    min-width: 76px;
    position: relative;
    top: 1px;
    font-weight: 600;
}

.delet_btn_journey:hover {
    background-color: #e01a4f;
    color: #fff;
}

.delet_btn_journey:hover i {
    color: #fff;
}

.deleteJourneyIcons {
    font-size: 15px;
    position: relative;
    top: 1px;
    left: -4px;
}

.selectType {
    width: 100%;
    float: left;
    position: relative;
    padding: 5px 10px;
    border: 1px solid #e7eaf0;
    border-radius: 4px;
    width: 100%;
    font-size: 13px;
    height: 40px;
    margin-bottom: 6px;
    cursor: pointer;

    .inputType {
        padding: 4px 0;
    }
}

.dropDown {
    position: absolute;
    right: 0;
    top: 5px;
}

.journeyInputShowDrop {
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #e7eaf0;
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, 0.2);
    border-radius: 4px;
    float: left;
    position: absolute;
    background: #fff;
    z-index: 99;
    top: 65px;
    left: 0;
}

.journeyChecks {
    display: block;
    float: left;
    width: 100%;
    padding: 4px 0px;

    p {
        margin: 0;
        padding: 0;
    }

    .chk {
        width: 100%;
        float: left;
    }
}

.journeyInputTimpan select {
    padding: 10px !important;
    border: 1px solid #e7eaf0;
    border-radius: 4px !important;
    outline: none;
    width: 100%;
}

.react-datepicker__input-container .journeyDatepannel {
    padding: 10px !important;
    border: 1px solid #e7eaf0;
    border-radius: 4px !important;
    outline: none;
    width: 100%;
}

.journeyDatepannel.react-datepicker-ignore-onclickoutside {
    padding: 10px !important;
    border: 1px solid #e7eaf0;
    border-radius: 4px !important;
    outline: none;
    width: 100%;
}

.timewidth {
    width: 110px;
}

.datewidth {
    width: 190px;
}


.journeyInputTimpan.datewidth .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 3.6px !important;
}

.journeyInputTimpan.datewidth .MuiAutocomplete-endAdornment.css-1q60rmi-MuiAutocomplete-endAdornment {
    background: #fff !important;
}

.journeyInputTimpan.datewidth .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #e7eaf0 !important;
}

.journeyInputTimpan.datewidth .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #e7eaf0 !important;
}


.journeyInputTimpan.datewidth .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    font-size: .8rem !important;
}

.calandWidth {
    width: 130px;
}

.addIconsDown {
    position: relative;
}

.addIconsDown {
    position: absolute;
    top: 8px;
    right: 2px;
}



.eventSelectSnap .css-p3xpc2 .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 1.5px 5px 1px 5px !important;
}

.daysWidth {
    width: 90px;
}

.canOnwidth {
    width: 80px;
}

.accountWidth {
    width: 260px;
}

.flexthCenter {
    position: relative;
    top: 15px;
}

.journeyMargin {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.journeyInput.journeyBottommargin {
    float: left;
    width: 100%;
    margin-bottom: 25px;
    position: relative;
    margin-top: -7px;
}



.iconsInforJourne {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    margin-left: 4px;
    font-size: 8px;
    line-height: 17px;
    cursor: pointer;
    position: relative;
    top: -1px;
}

.info-outline {
    width: 14px;
    position: relative;
    top: -4px
}

.journeyInfor {
    position: absolute;
    top: -31px;
    background: #666;
    padding: 5px 5px;
    border-radius: 3px;
    width: max-content;
    left: 0px;
    display: none;
    z-index: 100000;
}

.iconsInforJourne:hover .journeyInfor {
    display: block;
}

.jouneyInfoPannel {
    position: relative;
    color: #fff;
}

.jouneyInfoPannel::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 8px solid #666666;
    position: absolute;
    bottom: -10px;
    left: 0px;
}

.jouneyInfoPannel h3 {
    font-size: 12px;
    margin: 0px;
}

.journeyInfor.userTypeWidth {
    width: 210px !important;
}

.journeyInfor.journeyStartwidth {
    width: 240px !important;
    padding: 6px 5px;
    top: -48px;
    left: -7px;
}

.eventSelectSnap .css-18nc3u2 .MuiOutlinedInput-root {
    padding: 3px 0px 2px 0px !important;
}

.eventSelectSnap .css-x1krps-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 5px 0px 4px 0px !important;
}

.notSetUserType .stepPannelHeader,
.notSetUserType .fontCeo {
    background-color: #bb2100;
    color: #fff;
    border-bottom: 1px solid;
}

.isUserActive {
    background: #fff3f3;
}

.requiredFshow {
    position: relative;
    top: 0;
    left: 0;
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    background: #5D6E84;
    padding: 6px 10px;
    border-radius: 3px;
}

.flexspace {
    width: 100%;
    justify-content: space-between;
}

.infoIcon {
    position: absolute;
    right: -17px;
    top: -3px;

}

.duplicateIcon {
    width: 40px;
}

.duplicateIcon {
    width: 16px !important;
}

.infoIcon:hover .toolTip,
.rightwhiteinfo:hover .toolTip {
    display: block;
}

.toolTip {
    position: absolute;
    top: -72px;
    left: 0;
    width: 300px;
    background: #596A80;
    border-radius: 3px;
    padding: 5px 10px;
    color: #fff;
    display: none;
    text-transform: initial;
    font-weight: normal;
    z-index: 999;
}

.rightwhiteinfo .toolTip {
    left: -144px;
}

.infoIconImg {
    width: 13px;
}

.filtericon {
    position: relative;
    left: 10px;
    height: 14px;
}

.requiredField {
    padding: 2px 0 0 0;
}

.jselectbox {
    font-size: 12px;
    padding: 2px 0;
    border-color: #ccc;
    border-radius: 3px;
    position: relative;
    top: 3px;
}

.actionStatus {
    text-transform: initial;
}

.filterContainer {
    position: relative;
}

.filterBtn {
    border: 1px solid #E7EAF0;
    background-color: #fff;
    border-radius: 3px;
    font-size: 13px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 10px;
    cursor: pointer;
    height: 38px;
    margin-left: 15px;
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
}

.filterBtn:hover {
    background-color: #fbf9f9;
}

.filterPopup {
    position: absolute;
    left: 15px;
    top: 103%;
    width: 260px;
    height: 300px;
    overflow: auto;
    overflow-x: hidden;
    background-color: #fff;
    border: 1px solid #E7EAF0;
    z-index: 99;
    border-radius: 3px;
}

.filterbody {
    padding: 10px 15px;
    font-weight: 600;
}

.filterborder {
    border-bottom: 1px solid #E7EAF0;
    padding: 10px;
    font-weight: bold;
    margin: 0;
}

.filterUl,
.filterUl li,
.filterUl li label {
    padding: 0;
    list-style: none;
    margin: 0;
    float: left;
    width: 100%;
}

.filterUl li {
    padding: 3px 0;
}

.border-line {
    border: 0.2px solid #E7EAF0 !important;
    float: left;
    width: 100%;
    margin: 5px 0;
}

.tableProgress {
    display: inline-block;
    width: calc(100% - 45px);
    background: #46BFA5;
    height: 17px;
    border-radius: 50px;
    position: relative;
    top: 2px;
}

.progressNumber {
    margin-left: 6px;
    position: relative;
    font-size: 13px;
    top: -1px;
}

.gridjourneybox {
    float: left;
    width: calc(100% + 43px);
    margin-left: -23px;
    padding: 0px 15px;
    background: #F5F5F5;
}

.journeytablegrid {
    float: left;
    background: #fff;
    width: 100%;
}


.surveySearch input::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #E3E5E9;
}

.surveySearch input::placeholder {
    color: #979797;
}

.journeyRed {
    color: #F93C65;
    margin: 0px;
    text-decoration: underline;
}

.journeyPl5 {
    padding-left: 12px;
}

.setDotsjourney {
    position: absolute;
    right: 9px;
    top: 18px;
}


.infoIconSet {
    position: relative;
    top: -5px;
    left: 2px;
    cursor: pointer;
    display: inline-block;
}

.infoMeater {
    position: absolute;
    bottom: 26px;
    width: 160px;
    background: #666666;
    border-radius: 5px;
    left: -15px;
    padding: 10px;
    display: none;
}

.infoIconSet:hover .infoMeater {
    display: block;
}

.angleinfo {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 11px solid #666666;
    margin: 2rem;
    position: absolute;
    bottom: -40px;
    left: -20px;
}

.infoMeater p {
    color: #fff;
    margin: 0px;
    font-size: 12px;
}

.relativebox {
    position: relative;
}

.setScrollfilter {
    width: 100%;
    float: left;
    position: sticky !important;
    top: 0;
}

.setasearch {
    position: absolute;
    top: 9px;
    left: 15px;
    width: 15px;
}

.searchFilter::-ms-input-placeholder {
    /* Edge 12-18 */
    color: #E3E5E9;
    font: 12px;
}

.createjourneybtn {
    background-color: #46BFA5;
    color: #fff;
}

.createjourneybtn:hover {
    background-color: rgb(24, 105, 88);
}

.tablecolumdots {
    max-width: 142px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.annoucementiconbell {
    width: 30px !important;
    position: relative;
    left: -4px;
    top: -1px;
}

.seachBoxJO {
    width: 500px !important;
}

.surveySearch.marTop.journeyfullwidth {
    width: 100% !important;
}

.dataloading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.journeyLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}

.journeyLoading img {
    height: 50px;
    width: 50px;
}

.seachBoxJO {
    width: 500px !important;
}

.greenbg {
    background-color: #46BFA5;
}

.listLeftspace {
    padding-left: 20px !important;
}

.duedateStep {
    margin-top: 15px;
}

.surveyGrid {
    display: flex;
    margin-top: 6px;
}

.datepicforSurve.journeyml {
    margin-left: 0px;
}

.selectopt select {
    border: 1px solid #ddd;
    padding: 7px;
    border-radius: 4px;
}


.textseelct {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
    margin-top: 8px;
}

.nopaddingleft {
    padding: 8px;
    margin-top: 3px;
}

.duedatepannelset {
    display: block;
    float: left;
    width: 100%;
    margin: 10px 0px 0px;
}

.labletext {
    font-weight: bold;
    margin-bottom: 5px;
}

.actionItemTab,
.actionItemTab ul,
.actionItemTab ul li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.actionItemTab {
    float: left;
    width: 100%;
}

.actionItemTab ul li {
    padding: 5px 10px;
    font-size: 13px;
}

.activeActionTab {
    color: #2d8f7a;
}

.journeyStepCount {
    position: relative;
    right: 0;
    font-weight: normal;
    font-size: 12px;
    float: right;
}

.watchIcon {
    position: relative;
    top: -1px;
    left: -3px;
}

.girdjourneSpace {
    padding: 5px 15px;
    float: left;
    width: 100%;
    height: 100%;
    display: block;
}

.togglebtnPanne {
    cursor: pointer;
    transition: ease-in-out 0.5s;
}

.tablerowcoll {
    display: flex;
    width: 100%;
}

.childTable {
    display: flex;
    width: 95%;
    margin-left: 80px;
    padding-top: 10px;
    border-top: 1px solid #adb8c47a;
    margin-top: 10px;
}

.col-norm {
    width: 20%;
    padding: 0px 0px 0px;
    color: #4C5F7E;
    font-size: 12px;
    font-weight: 600;
}

.col-norm p {
    margin: 0px;
    margin-top: 10px;
    padding-left: 5px;
    font-size: 14px;
    font-weight: bold;
}

.childWidth-16 {
    width: 16%;
}

.listTextBold {
    text-decoration: underline;
    cursor: pointer;
    float: right;
    margin-right: 20px;
}


.col-norm.childWidth-10 {
    width: 205px;
    border-right: 1px solid #adb8c47a;
    margin-right: 38px;
}

.ctptop {
    margin-top: 11px;
}


.StepDetail {
    width: 500px;
    background: #fff;
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 2px 4px rgba(205, 205, 205, 0.2) !important;
    border: 1px solid#ddd;
    border-radius: 5px;
    z-index: 1000;
}

.stepDetailHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stepDetailHead span {
    cursor: pointer;
}

.react-responsive-modal-overlay {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
}

.stepDetailHead h2 {
    font-size: 16px;
    margin: 8px auto 6px auto;
    font-weight: bold;
}


.stepDetailHead {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}

.tablePopup {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sortPannel {
    background: #d6dbe28f;
    padding: 5px 15px;
    position: relative;
}

.sortPannel h3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 28px;
    margin: 0px;
}

.greentextUser {
    color: #46BFA5;
    cursor: pointer;
    margin-left: 20px;
}



/*ncheck*/
.nCheckbox {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0px;
}

/* Hide the browser's default checkbox */
.nCheckbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.ncheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #E7EAF0;
    border-radius: 3px;
    border: 1px solid #D6DBE2;
}

/* On mouse-over, add a grey background color */
.nCheckbox:hover input~.ncheckmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.nCheckbox input:checked~.ncheckmark {
    background-color: #46BFA5;
}

/* Create the ncheckmark/indicator (hidden when not checked) */
.ncheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the ncheckmark when checked */
.nCheckbox input:checked~.ncheckmark:after {
    display: block;
}

/* Style the ncheckmark/indicator */
.nCheckbox .ncheckmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


.checkboxTablestep {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid #D6DBE2;
    align-items: center;
    justify-content: space-between;
}

.userTextTopic {
    font-size: 12px;
    font-weight: bold;
}

.mediaStep {
    display: flex;
    align-items: center;
    justify-content: center;
}

.userTextStep h2 {
    font-size: 12px;
    font-weight: bold;
    margin: 0px;
    position: relative; 
}
.startOn{
    width: 350px;
    position: relative;
    top: -11px;
    color: #5f5f5f;
    font-size: 12px;
}
.userTextStep {
    margin-left: 10px;
}

.userTextStep p {
    color: #596A80;
    font-size: 10px;
    font-weight: bold;
    margin: 0px;
}

.pictureStep {
    width: 35px;
}

.checkmarkMedia {
    margin-top: 8px;
}

.stuckStep {
    color: #E01A4F;
    font-weight: bold;
}

.CompletedStep {
    color: #46BFA5;
    font-weight: bold;
}

.bottomformEmail {
    float: left;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #46BFA5 !important;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}

.emailIcons {
    width: 26px;
    position: relative;
    top: -2px;
}

.stepStatus {
    position: absolute;
    background: #fff;
    z-index: 1;
    padding: 0px;
    box-shadow: 4px 4px 8px rgba(205, 205, 205, 0.5) !important;
    left: 92px;
    width: 140px;
    border: 1px solid #ddd;
    border-radius: 6px;
}

.iconDropdown {
    width: 16px;
}

.stepStatus ul {
    margin: 0px;
    padding: 0px;
}

.stepStatus ul li {
    list-style: none;
    font-weight: bold;
    font-size: 12px;
    color: 303030;
    padding: 10px 15px;
    cursor: pointer;
}

.stepStatus ul li:hover {
    opacity: 0.7;
}

.childwidth {
    width: 103px;
    border-right: 1px solid rgba(173, 184, 196, 0.4784313725);
    margin-right: 38px;
}

.norm-w-60 {
    width: 60%;
}



.togglebtnPanne.togglemove img {
    transform: rotate(181deg);
}

.overlayNewPopup {
    background: rgba(0, 0, 0, .75);
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    padding: 1.2rem;
}

.overflowStats {
    height: 400px;
    overflow: auto;
    overflow-x: hidden;
    float: left;
    width: 100%;
}

#openLinkInNewWindow {
    width: auto;
}

.disablebottomformEmail {
    color: #8c8c8c;
}

.DraftEditor-root {
    a {
        color: #46bfa5;
    }
}

.paddingallbox {
    padding: 15px;
}


.sentemaibtn {
    margin-left: 10px;
    border: 1px solid #ddd;
    background: #e5e5e5;
    padding: 8px 10px;
    border-radius: 5px;
    position: relative;
    top: -1px;
}

.sentemaibtn:hover {
    background-color: #ddd;
}

.iconSend {
    margin-right: 5px;
    width: 15px;
    opacity: 0.7;
    position: relative;
    top: -1px;
}


.journeySendRmider {
    display: block;
    width: 500px;
    border-radius: 4px;
    border: 1px solid #ddd;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px 10px 10px;
    text-align: center;
}

.journeysendRmiderHeading {
    font-size: 20px;
    font-weight: bold;
    color: #46BFA5;
}

.sendbtnpopup {
    border: 1px solid #46BFA5;
    color: #46BFA5;
    border-color: #46BFA5;
    padding: 8px 15px;
    float: left;
    border-radius: 30px;
    background-color: #fff;
    font-size: 12px;
    cursor: pointer;
    font-weight: 400;
    width: 80px;
    text-align: center;

}

.sendbtnpopup:hover {
    background-color: #46BFA5;
    color: #fff;
}

.buttonpopdown {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 13px;
}


.emiliconjourney {
    width: 30px;
    position: relative;
    top: -2px;
}

.journeySendRmider p {
    font-weight: bold;
    font-size: 16px;
}

.setrowrap {
    flex-wrap: wrap;
}


.colorbland.setnewtaelrespadd {
    padding: 10px 20px;
}

.setrowrap {
    flex-wrap: wrap;
}



.putTextJourney {
    display: inline-block;
    width: 45px;
    font-size: 14px;
}

.setTextJourney {
    font-weight: bold;
    font-size: 14px;
}


.journey-Completed {
    color: #46BFA5;
}

.journey-In-Progress {
    color: #e1d70f;
}

.journey-Overdue {
    color: #303030;
}

.journey-Stuck {
    color: #E01A4F;
}



.editHeadingJourney input {
    border: 1px solid #ddd;
    padding: 8px 16px;
    border-radius: 5px;
    outline: none;
    width: 350px;
}


.editHeadingJourney {
    display: flex;
}

.saveBtnJourney {
    border: 1px solid #46bfa5;
    padding: 0px 20px;
    border-radius: 20px;
    margin: 0px 10px;
    color: #46bfa5;
    background-color: #fff;
}

.saveBtnJourney:disabled {
    opacity: 0.5;
}

.saveBtnJourney:hover {
    background-color: #46bfa5;
    color: #fff;
}


.textstatus {
    text-transform: lowercase;
}

.textstatus::first-letter {
    text-transform: capitalize;
}

.ifthenCondition {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    max-width: 550px;
    z-index: 99999;
}

// .ifthenheader ul,
.ifthenheader li {
    float: left;
    list-style: none;
    margin: 0;
    padding: 4px 10px 4px 0px !important;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
    border-radius: 3px;
    margin-right: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;
}

.ifthenheader .active {
    color: #46BFA5;
}

.journeyTabs {
    margin: 0;
    padding: 0px;
    width: 100%;
}


.ifthenbody {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 10px;
}


.selectBox {
    width: 100%;
    border: 1px solid #a6a6a6;
    padding: 8px 8px;
    margin-bottom: 7px;
    color: #323232;
    font-size: 13px;

}

.selectDoneTab {
    width: 90px;
}

.ifthenCalendar {
    border: 1px solid #a6a6a6;
    float: left;
    padding: 1px 3px;
    margin: 0px 10px 0px 0;
    width: 64%;
    height: 37px;
}

.iftextarea {
    width: 100%;
    border: 1px solid #ccc;
    height: 200px;
    padding: 5px;
    outline: none;
}

.ifthenbtn {
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 14px;

}

.conditionifbox {
    display: block;
    width: 200px;
    background: #fff;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
}



.linecondition {
    height: auto;
    width: 390px;
    display: flex;
    margin: 25px auto 0 auto;
    position: relative;
}

.linecondition::after {
    content: "";
    width: 50%;
    height: 1.5px;
    background: #a8a8a8;
}






.addpluse.ifconditionareo {
    position: absolute;
    left: 0;
}

.addpluse.thenconditionareo {
    position: absolute;
    right: 0;
}

.conditionbox {
    width: 500px;
    margin: 0 auto;
    position: relative;
}

.conditionifbox {
    display: block;
    width: 300px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    float: left;
    margin: 30px 0px 0px -100px;
}

.conditionthenbox {
    display: block;
    width: 300px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    float: right;
    margin: 30px -100px 0px 0px;
}

.conditionboxarea {
    box-shadow: 0 2px 4px hsla(0, 0%, 80.4%, .2);
}

.conditionboxarea h3 {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    margin: 0px;

}

.addpluse.secondlogline {
    height: 90px;
    margin-top: -80px;
}

.Headiercondtion.upersethead {
    text-align: center;
    padding: 10px;
    font-size: 15px;
}


.linecondition::before {
    content: "";
    width: 1.6px;
    height: 153px;
    background: #a8a8a8;
    position: absolute;
    left: 49.8%;
    top: -20px;
}

.Headiercondtion {
    background: #000;
    color: #fff;
    border-radius: 5px 5px 0px 0px;
    position: relative;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    padding: 3px 10px;
    font-weight: bold;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
}

.rightSide {
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chkDisabled {
    opacity: 0.6;
}

.lastCl::before {
    height: 21.3px !important;
}

.sideInfo {
    position: relative;
    top: -3px;
    right: -9px;
    color: #fff;
    cursor: pointer;

    img {
        fill: red;
    }
}

.jdropDonw {
    float: right;
}

.nodeType {
    position: relative;
    top: 4px; 
}

.no-cursor {
    cursor: auto;
}

.ifthenheader {
    margin-bottom: 23px;
    width: 100%;
    float: left;
}

.changHints {
    position: relative;
    top: -6px;
}

.text-blue {
    color: #0562f8;
}

.w100px {
    width: 100px;
}

.chatIcon {
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
}

.chatIconCont {
    position: relative;
    display: flex;
}

.greentext {
    font-size: 14px;
    color: #46bfa5;
}
.redtext {
    font-size: 14px;
    color:red;
}

.closeIconSvg{
    width: 20px;
    position: relative;
    top: 6px;
    &:hover{
        opacity: 0.5;
    }
}

// .tooltipPoint {
//     position: absolute;
//     top: -128px;
//     left: -210px;
//     width: 300px;
//     background: #ffffff;
//     border-radius: 3px;
//     height: 124px;
//     z-index: 9999;
//     display: none;
//     padding: 10px;
//     overflow: scroll;
//     border: 1px solid #e2e2e2;
//     overflow-x: hidden;
// }

// &:hover .tooltipPoint {
//     display: block;
// }

.acceptSMbtn{
    padding: 5px 9px;
}

.latterCount {
    position: absolute;
    right: 24px;
    bottom: 66px;
}

.acceptDecline {
    padding: 4px 6px;
    background-color: #2d8f7a;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
}

.acceptDecline:hover {
    background-color: #33a28a;
}

.journeyLink{
    color: #027a60;
}





















@media screen and (max-width: 1500px) {
    .linecondition::before {
        content: "";
        width: 1.4px;
        height: 153px;
        background: #a8a8a8;
        position: absolute;
        left: 49.9%;
        top: -20px;
    }

    .lastCl::before {
        height: 21.3px !important;
    }
}

@media screen and (max-width: 767px) {
    .searchboxRight {
        width: 100%;
    }

    .responsiveScroll.setterwidthjourney {
        width: 820px !important;
    }

    .linecondition {
        display: none;
        width: 100%;
    }

    .conditionthenbox {
        position: static;
        width: 100%;
        margin: 0px;
    }

    .conditionifbox {
        position: static;
        width: 100%;
        margin: 10px 0px 10px 0px;
    }

    .conditionbox {
        width: 100%;
        margin: 0 auto;
        position: static;
        display: block;
    }
}